export default {
  props: {
    sPurchaseOrderId: {
      type: String,
    },
    sProviderId: {
      type: String,
    },
    aCategory: {
      type: Array,
    },
    dTotalPallets: Number,
    dRatePerUSD: Number,
  },
  data() {
    return {
      texts: "",
      dTotalPalletsEdit: 0,
      dRatePerUSDEdit: 0,
      screenWidth: 0,
      bLoading: false,
      aSubcategories: [],
      sCategoryId: null,
      sSubcategoryId: null,
      aProducts: [],
      dDeliveredQuantity: 0,
      dDeliveredUnitPrice: 0,
      sProductId: null,
      aProduct: [],
      sProductSelectedId: "",
      oProduct: {},
      iWeigthPer: 0,
      iAproxPiecesPer: 0,
      sNumberPerPallet: 0,
      iStorageTemperature: 0,
      iTransportationTemperature: 0,
      iInventory: 0,
      iMinimumSellingAmount: 0,
      sNameMeasurementUnit: "",
      iPricePerPallet: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.ordersTexts[this.selectLanguage];
    // this.getCategories();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.closeButton();
  },
  methods: {
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    addProductsOrder: function() {
      this.bLoading = true;
      const payload = {
        dDeliveredQuantity: this.dDeliveredQuantity,
        // dDeliveredUnitPrice: this.aProduct,
        sProductId: this.sProductId,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.sPurchaseOrderId}/products`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.closeButton();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.$emit("blockBtn", false);

          this.mixError(error.response.data.message, error.response.status);
        });
    },

    getSubCategories: function() {
      if (this.sCategoryId) {
        DB.get(
          `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.sProviderId}/categories/${this.sCategoryId}/subcategories`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            // params: {
            //   sCategoryId: this.sCategoryId,
            // },
          }
        )
          .then((response) => {
            this.aSubcategories = response.data.results;
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
    getProducts: function() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          sSubcategoryId: this.sSubcategoryId,
          sVendorEnterpriseId: this.sProviderId,
          bDropDown: true,
        },
      })
        .then((response) => {
          this.aProducts = response.data.results;
          // if (this.selectLanguage == "sp") {
          //   this.aProducts.unshift({ sName: "Todos", sProductId: "" });
          // } else {
          //   this.aProducts.unshift({ sName: "All", sProductId: "" });
          // }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getProductById: function() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/products/${this.sProductId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          // this.aProduct = response.data.results.dUnitPrice
          this.oProduct = response.data.results;
          this.iWeigthPer =
            response.data.results.oMeasurementUnit.oData.dWeightPerBox;
          this.iAproxPiecesPer =
            response.data.results.oMeasurementUnit.oData.dPiecesPerBox;
          this.sNumberPerPallet =
            response.data.results.oMeasurementUnit.oData.dBoxesPerPallet;
          this.sNameMeasurementUnit =
            response.data.results.oMeasurementUnit.sName;
          this.iStorageTemperature = response.data.results.dStorageTemperature;
          this.iTransportationTemperature =
            response.data.results.dTransportationTemperature;
          this.iInventory = response.data.results.dInventoryQuantity;
          this.iMinimumSellingAmount = response.data.results.dMinAmountAllowed;

          this.iPricePerPallet = response.data.results.oMeasurementUnit.oData.dPricePerPallet;

          // .map((e) => {

          //   return {
          //     dDeliveredUnitPrice: e.dUnitPrice,
          //   };
          // });
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    closeButton: function() {
      this.sCategoryId = "";
      this.sSubcategoryId = "";
      this.sProductId = "";
      this.dDeliveredQuantity = "";
      this.iWeigthPer = 0;
      this.iAproxPiecesPer = 0;
      this.sNumberPerPallet = 0;
      this.sNameMeasurementUnit = "";
      this.iStorageTemperature = 0;
      this.iTransportationTemperature = 0;
      this.iInventory = 0;
      this.iMinimumSellingAmount = 0;
      this.iPricePerPallet = 0;
      this.$store.commit("setDialogAddProducts", {
        active: false,
        arr: [],
      });
    },
    soloNumbers: function(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    emitClose: function() {
      this.$store.commit("setDialogAddProducts", {
        active: false,
        arr: [],
      });
    },
    getNumberPallet(sQuantity) {
      if (sQuantity) {
        return Math.ceil(parseInt(sQuantity) / this.sNumberPerPallet);
      } else {
        return 0;
      }
    },
  },
  computed: {
    dialogAddProductsActive: function() {
      return this.$store.state.dialogAddProductsActive;
    },
    dialogAddProductsArr: function() {
      return this.$store.state.dialogAddProductsArr;
    },
    selectLanguage: function() {
      return this.$store.state.language;
    },
    validateForm: function() {
      return (
        this.sCategoryId !== "" &&
        this.sSubcategoryId !== "" &&
        this.sProductId !== "" &&
        this.dDeliveredQuantity !== ""
      );
    },
  },
  watch: {
    dialogAddProductsActive: function() {
      if (this.dialogAddProductsActive) {
        this.dTotalPalletsEdit = this.dTotalPallets;
        this.dRatePerUSDEdit = this.dRatePerUSD;
        this.texts = FILE.ordersTexts[this.selectLanguage];
        // this.getStatus();
      }
    },
    sCategoryId() {
      if (this.sCategoryId) {
        this.sSubcategoryId = null;
        this.sProductId = null;
        this.getSubCategories();
      } else {
        this.sSubcategoryId = null;
        this.sProductId = null;
      }
    },
    sSubcategoryId() {
      if (this.sSubcategoryId) {
        this.sProductId = null;
        this.getProducts();
      } else {
        this.sProductId = null;
      }
    },
    sProductId() {
      if (this.dialogAddProductsActive) {
        if (this.sProductId) {
          this.dDeliveredQuantity = null;
          this.getProductById();
        } else {
          this.iWeigthPer = 0;
          this.iAproxPiecesPer = 0;
          this.sNumberPerPallet = 0;
          this.sNameMeasurementUnit = "";
          this.iStorageTemperature = 0;
          this.iTransportationTemperature = 0;
          this.iInventory = 0;
          this.iMinimumSellingAmount = 0;
          this.dDeliveredQuantity = null;
          this.iPricePerPallet = 0;
        }
      }
    },
    dDeliveredQuantity() {
      this.dTotalPalletsEdit = this.dTotalPallets;
      if (this.dDeliveredQuantity) {
        this.dTotalPalletsEdit =
          this.dTotalPalletsEdit +
          this.getNumberPallet(this.dDeliveredQuantity);
      }
    },
  },
};
